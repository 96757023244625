import React, {useState,useEffect} from 'react'
import Layout from "../components/layout"
import '../styles/batch-calendar.css'
import {Form, Alert} from 'react-bootstrap'
import {graphql, Link} from 'gatsby'
import { isLoggedIn, getCurrentUser } from "../utils/auth"

export const data = graphql`
query {
   allCoursePageDataset(filter: { Type: { eq: "Cram Class Online" } }) {
     edges {
       node {
         Id
         Title
         Type
         SlugURL
       }
     }
   }
   allBatchDataset {
     edges {
       node {
         Name
         Branch
         Id
         Courses
         StartDate
         EndDate
         IsActive
         Code
         ActualPrice
         DiscountedPrice
         Duration
         Mode
         AdmissionDeadline
         ThumbnailURL
         Branches {
           BranchId
           StartTime
           EndTime
           Price
           DiscountedPrice
         }
       }
     }
   }
   allBranchDataset {
     edges {
       node {
         Name
         Country
         Code
         State
         Id
         PaymentType
       }
     }
   }
 }`;

// const getData = (allBatches,state) => {
//    const filterData = allBatches.filter((batch) => {
      
//       let booleanValue = false;
      
//       if(state.country) {
//          booleanValue = batch.node.Branches.map( (item) => item.BranchId ).includes(state.country);
//       }

//       if(state.mode !== "all") {
//          //   booleanValue = booleanValue && batch.node.Mode === state.node;
//       }

//       return booleanValue
//    })
//    return filterData
// }

const BatchCalendar = ({data}) => {

   const [userBatches, setUserBatches] = useState(null);

   // const [batchDataState, setBatchDataState] = useState(
   //    {
   //       country: null,
   //       mode: 'all',
   //       course: null
   //    }
   // )

   // const handleChangeSelect = (props) => (event) => {
   //    setBatchDataState({
   //       ...batchDataState, [props]:event.target.value
   //    })
   // }

   useEffect( () => {

      if(isLoggedIn()) {

         const { token } = getCurrentUser()
         
         fetch('https://dlunnbffs2.execute-api.ap-south-1.amazonaws.com/v1/get-user', {
         method: 'get',
         headers: {
            "Content-Type": "application/json",
            "Authorization": token
         }
         }).then(function(response) {
            return response.json();
         }).then(function(res) {
            
            if(res.data.Item.Batches.length > 0) {
               setUserBatches(res.data.Item.Batches)
            }

         });
      }
  }, []
  )

   const course = data.allCoursePageDataset.edges;
   const batches = data.allBatchDataset.edges;
   const branch = data.allBranchDataset.edges;
   
   // const getFilteredValue = React.useMemo(() => getData(batches,batchDataState), [batchDataState])

   const countries = branch.filter((country) => {
      return country.node.State==="ALL"
   }).map((item) => {
      return {country: item.node.Country, id: item.node.Id, currency: item.node.PaymentType}
   })

   const [batchlist, setBatchList] = useState(
      {
         data: null,
         mode: null,
         order: null,
         batch: null,
         country: '',
         courses: null,
         msg: 'Please select a value from the dropdown'
      }
   );

   const handleChangeCountry = (event) => {

   const data = batches.filter((batch) => {
         return batch.node.IsActive && batch.node.Branches.map( (item) => item.BranchId ).includes(event.target.value)
      })
      
      let countryId = event.target.value;

      setBatchList((prevState) => {
         return { ...prevState, country: countryId, data: data, mode: ['Cram Class Online'], courses: course, batch: data };
      });

   }

   const handleChangeMode = (event) => {

      const courseData = course.filter((singleCourse) => {
         return singleCourse.node.Type === event.target.value;
      } )

      const modeBatch = batchlist.batch.filter( (item) => {
         return event.target.value ==="all" ? item.node : item.node.Mode === event.target.value;
      } )

      setBatchList((prevState) => {
         return { ...prevState, data: modeBatch, courses: courseData };
      });

   }

   const handleChangeCourse = (event) => {
      const courseData = batchlist.batch.filter( (item) => {
         return event.target.value ==="all" ? item.node : item.node.Courses.includes(event.target.value);
      } )

      setBatchList((prevState) => {
         return { ...prevState, data: courseData };
      });
   }

   const getCourseNameByBatchId = courseId => {
      let curentCourse = batchlist.courses && batchlist.courses.filter(item => courseId.includes(item.node.Id))
      return curentCourse;
   }

   const getUserBatchesById = (batchID) => {
      let result = userBatches && userBatches.filter((item) => {
         return item.Id === batchID
      })
      return result;
   }

   const getCurrencyByCountryId = (countryId) => {
      return countries.filter( country => country.id === countryId)[0].currency
   }

   // const changeCourseMode = (event) => {
     
   //   let courseModeData =  course.filter(item => {
   //       return (item.node.Type === event.target.value)
   //    })

   //   let data =  batches.filter(val => { 
   //    return courseModeData.some(item => val.node.Courses !== null && val.node.Courses.includes(item.node.Id) && (val.node.IsActive))
   //    })
     
   //    let locationData =  branch.filter(val => data.some(item => {
   //      return val.node.Id === item.node.Branch
   //   }))

   //   setBatchList((prevState) => {
   //    return { ...prevState, courses: courseModeData.length !== 0 ? courseModeData : null,data: data.length != 0 ? data : null, batch: data.length != 0 ? data : null, country: locationData.length != 0 ? locationData : null };
   //   });
 
   // }

   // const changeCourse = (event) => {
       
   //    let courseData = batchlist.batch && batchlist.batch.filter(item => {
   //       return (event.target.value === 'all') ? item.node.IsActive : item.node.Courses.includes(event.target.value)
   //    })
 
   //    let locationData =  branch.filter(val => courseData.some(item => {
   //       return val.node.Id === item.node.Branch
   //    }))

   //    setBatchList((prevState) => {
   //       return { ...prevState, data: courseData.length != 0 ? courseData : null, country: locationData.length != 0 ? locationData : null };
   //      });
      
   // }

   // const changeCountry = (event) => {
   //    let courseData = batchlist.data && batchlist.data.filter(item => {
   //       return event.target.value === 'all' ? item.node.IsActive : item.node.Branch === event.target.value 
   //    })

   //    setBatchList((prevState) => {
   //       return { ...prevState, data: courseData.length != 0 ? courseData : null};
   //      });
   // }

   // const getCourseNameByBatchId = courseId => {
   //    let curentCourse = batchlist.courses && batchlist.courses.filter(item => courseId.includes(item.node.Id))
   //    return curentCourse;
   // }

   // const getLocationByBranchId = branchId => {
   //    let curentBranch = branch && branch.filter(item => branchId.includes(item.node.Id))
   //    return curentBranch[0].node.Country;
   // }

   // const getUserBatchesById = (batchID) => {
   //    let result = userBatches && userBatches.filter((item) => {
   //       return item.Id == batchID
   //    })

   //    return result
   // }

    return (
        <Layout>
            <div className="batchCLOuter">
              <div className="container">
                 <h2>Batch Calendar</h2> 
                 <div className="row mt-5">
                     <div className="col-md-3">
                        <Form.Group controlId="course-mode">
                        <Form.Label>Country</Form.Label>
                        <Form.Control name="country" as="select" onChange={handleChangeCountry}>
                            <option value="all" disabled selected> -- select -- </option>
                            {countries.map((country, index) => (
                              <option value={country.id} data-currency={country.currency} key={index}>{country.country}</option>
                            ))}
                        </Form.Control>
                        </Form.Group>  
                     </div>
                     <div className="col-md-3">
                        <Form.Group controlId="course-mode">
                        <Form.Label>Mode</Form.Label>
                        <Form.Control name="course-mode" as="select" onChange={handleChangeMode}>
                        <option value="all"> -- select -- </option>
                            {batchlist.mode && batchlist.mode.map( item => {
                               return (
                                  <option value={item}>{item}</option>
                               )
                            })}
                        </Form.Control>
                        </Form.Group>  
                     </div>
                     <div className="col-md-3">
                        <Form.Group controlId="courses">
                        <Form.Label>Courses</Form.Label>
                        <Form.Control name="courses" as="select" onChange={handleChangeCourse}>
                            {
                            batchlist.courses === null ? <option value="all"> -- select -- </option> : <option value="all"> All Courses </option>
                            }
                            {
                               batchlist.mode &&
                               batchlist.courses.map(item => {
                                 return (
                                 <option value={item.node.Id}>{item.node.Title}</option>
                                 )
                              })
                            }
                        </Form.Control>
                        </Form.Group>
                     </div>
                     {/* <div className="col-md-3">   
                        <Form.Group controlId="country">
                        <Form.Label>Location</Form.Label>
                        <Form.Control name="country" as="select" onChange={changeCountry}>
                           
                            {
                            batchlist.country === null ? <option value="all"> -- select -- </option> : <option value="all"> All Country </option>
                            }
                           
                           {
                            batchlist.country &&
                               batchlist.country.map(item => {
                                 return (
                                    <option code={item.node.Code} value={item.node.Id}>{item.node.Country}</option>
                                 )
                              })
                            }
                        </Form.Control>
                        </Form.Group>
                     </div> */}
                 </div> 


                 <div className="batchClListOuter">
                     
{/* {
    !batchlist.data && <Alert className="d-block w-100" variant="danger" style={{ fontSize: "14px", textAlign: "center" }}>{batchlist.msg}</Alert>
} */}
{batchlist.data && batchlist.data.length > 0 ? 

<div className="row">
   {
      batchlist.data && batchlist.data.map((item) => {
         let courseDTL =  getCourseNameByBatchId(item.node.Courses)
         let getUserBatch = userBatches && getUserBatchesById(item.node.Id)
         let countryDetails = item.node.Branches.filter( item => item.BranchId === batchlist.country );
         
         return (
                        <div className="col-md-4">
                           <div className="batchCard">
                              <div className="batchImg">
                                 <img src={(item.node.ThumbnailURL ? item.node.ThumbnailURL :'https://placeimg.com/500/250/any')} alt={item.node.Name} />
                              </div>
                              <div className="batchContent">
                              <h4>{item.node.Name}</h4>
                              <p>Courses : {courseDTL && courseDTL.map(item => { return item.node.Title }).join(", ")}</p>
                              <p>Date: {item.node.StartDate} to {item.node.EndDate}</p>
                              <p>Timings : {item.node.StartTime} - {item.node.EndTime}</p>
                              <p>Batch ID : {item.node.Code}</p>
                              </div>
                              <div className="batchFooter">
                              <h6>{`${getCurrencyByCountryId(countryDetails[0].BranchId)} `}{parseInt(countryDetails[0].Price) - parseInt(countryDetails[0].DiscountedPrice)} <span>{`${getCurrencyByCountryId(countryDetails[0].BranchId)} `}{countryDetails[0].Price}</span></h6>
                                 
                                 {!isLoggedIn() && <Link to={`/app/login/?enroll=${item.node.Id}&&code=${countryDetails[0].BranchId}`} className="batchLink">Enroll</Link>}
                                 
                                 { isLoggedIn() ? getUserBatch && getUserBatch.length > 0 ? <Link to={null} className="batchLink enrolled">Already Enrolled</Link> : <Link to={`/app/enroll/${item.node.Id}/?code=${countryDetails[0].BranchId}`} className="batchLink">Enroll</Link> : '' }

                              </div>
                            </div>
                        </div>
         )
      })
   }
   </div>

: <Alert className="d-block w-100" variant="danger" style={{ fontSize: "14px", textAlign: "center" }}>{batchlist.msg}</Alert>}

{/* {

   batchlist.data &&
   batchlist.data.map(item => {
     let courseDTL =  getCourseNameByBatchId(item.node.Courses)
     let getUserBatch = getUserBatchesById(item.node.Id)
     let currency;
     
     switch(item.node.PaymentType) {
      case 'INR':
         // currency = '₹';
         currency = 'INR';
        break;
      case "SGD":
         currency = 'SGD';
        break;
      case "RM":
         currency = 'RM';
        break;
      default:
         currency = 'USD';
    }

     return (
      <div className="col-md-4">
                            <div className="batchCard">
                              <div className="batchImg">
                                 <img src={(item.node.ThumbnailURL ? item.node.ThumbnailURL :'https://placeimg.com/500/250/any')} alt={item.node.Name} />
                              </div>
                              <div className="batchContent">
                              <h4>{item.node.Name}</h4>
                              <p>Courses : {courseDTL.map(item => { return item.node.Title }).join(", ")}</p>
                              <p>Location : {getLocationByBranchId(item.node.Branch)}</p>
                              <p>Date: {item.node.StartDate} to {item.node.EndDate}</p>
                              <p>Timings : {item.node.StartTime} - {item.node.EndTime}</p>
                              <p>Batch ID : {item.node.Code}</p>
                              </div>
                              <div className="batchFooter">
                                 <h6>{`${currency} `}{parseInt(item.node.ActualPrice) - parseInt(item.node.DiscountedPrice)} <span>{`${currency} `}{item.node.ActualPrice}</span></h6>
                                 
                                 {!isLoggedIn() && <Link to={`/app/login/?enroll=${item.node.Id}`} className="batchLink">Enroll</Link>}
                                 {isLoggedIn() ? getUserBatch && getUserBatch.length > 0 ? <Link to={null} className="batchLink enrolled">Already Enrolled</Link> : <Link to={`/app/enroll/${item.node.Id}`} className="batchLink">Enroll</Link> : <Link to={`/app/enroll/${item.node.Id}`} className="batchLink">Enroll</Link> }

                              </div>
                            </div>
                         </div> 
     )
  })
 
} */}
                           

                     {/* </div>    */}
                 </div>
                 
              </div> 
            </div>
        </Layout>
    );
};

export default BatchCalendar;